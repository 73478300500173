<template>
  <div class="tw-flex tw-flex-col tw-animate-pulse tw-w-full">
    <loading-label v-if="label"></loading-label>

    <div
      class="tw-flex tw-items-center tw-w-full tw-input--height tw-input--pl tw-pr-6 tw-rounded tw-relative"
      :class="altBg ? 'tw-bg-theme' : 'tw-bg-theme-1'"
    >
      <div
        class="tw-w-20 tw-h-2 tw-rounded-full"
        :class="altBg ? 'tw-bg-theme-1' : 'tw-bg-theme'"
      ></div>
      <div
        class="tw-pointer-events-none tw-absolute tw-w-10 tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-4 tw-text-theme-base"
      >
        <font-awesome-icon icon="angle-down" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingLabel from '@components/Loading/LoadingLabel.vue'
export default {
  components: {
    LoadingLabel,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    altBg: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
